<template>
  <div>
    <my-nav-bar
      title="签单明细"
      left-text="返回"
      left-arrow
      right-text="收款明细"
      @click-right="toUnpaidReceiveView"
    />
    <div class="filter-container">
      <van-cell title="开始日期" is-link :value="listQuery.begin_date" center @click="showDatePicker('begin')" />
      <van-cell title="结束日期" is-link :value="listQuery.end_date" center @click="showDatePicker('end')" />
      <van-search v-model="listQuery.num" placeholder="请输入订单编号" @search="onSearch" />
    </div>
    <div class="app-main-container">
      <div class="list">
        <van-empty v-if="showEmpty" description="暂无数据" />
        <van-checkbox-group ref="checkboxGroup" v-model="receiveData.nums" @change="handleCheckboxChange">
          <div v-for="(order, index) in list" :key="order.num" class="unpaid-container" @click="handleToggle(index)">
            <van-checkbox :ref="'checkbox-' + index" class="unpaid-check" :name="order.num" />
            <div class="unpaid-info">
              <van-cell title="客户名称" :value="order.client.name" center />
              <van-cell title="单据编号" :value="order.num" center />
              <van-cell v-if="order.unpaid_amount > 0" title="签单金额" :value="'￥' + order.unpaid_amount" center value-class="cell-value" />
              <van-cell v-if="order.unpaid_return_amount < 0" title="签单退货金额" :value="'￥' + order.unpaid_return_amount" center value-class="cell-value" />
              <van-cell title="下单时间" :value="order.created_at" center />
            </div>
          </div>
        </van-checkbox-group>
      </div>
    </div>
    <div class="fixed-bottom-btn">
      <div>
        <van-cell title="全选" @click="handleCheckAll">
          <template #right-icon>
            <van-checkbox v-model="checkAll" />
          </template>
        </van-cell>
      </div>
      <van-button :disabled="receiveData.nums.length === 0" block square @click="handleReceive">收款</van-button>
    </div>

    <my-dialog v-model="show" title="确认收款" show-cancel-button @confirm="receiveOrder">
      <div class="confirm-data">
        <van-cell v-if="chooseAmount > 0" value-class="cell-value" title="签单总额" :value="'￥' + chooseAmount" />
        <van-cell v-if="chooseReturnAmount < 0" value-class="cell-value" title="退货总额" :value="'￥' + chooseReturnAmount" />
        <van-cell title="转成欠条" center>
          <van-switch v-model="receiveData.to_iou" size="20px" />
        </van-cell>
        <van-cell value-class="receive-mode-value" title="收款方式" center>
          <van-radio-group v-model="receiveData.receive_mode" direction="horizontal">
            <van-radio class="receive-radio" icon-size="16px" :name="1">现金</van-radio>
            <van-radio class="receive-radio" icon-size="16px" :name="2">微信</van-radio>
            <van-radio class="receive-radio" icon-size="16px" :name="3">转账</van-radio>
          </van-radio-group>
        </van-cell>
        <van-cell title="收款日期" :value="receiveData.receive_date" is-link center @click="showDatePicker('receive')" />
        <van-field v-if="receiveData.to_iou" v-model="receiveData.real_amount" label="实收金额" placeholder="请输入实收金额" type="number" />
      </div>
    </my-dialog>

    <van-popup v-model="showPop" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @confirm="handleChooseDate"
        @cancel="showPop = false"
      />
    </van-popup>

  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getClientUnpaidDetail, receiveUnpaid } from '@/api/client-unpaid'
import MyDialog from '@/components/my-dialog'
import { toDateString } from '@/utils'
// import dayjs from 'dayjs'
export default {
  components: { myNavBar, MyDialog },
  data() {
    return {
      listQuery: {
        client_id: this.$route.query.client_id,
        // 默认显示上一个月份
        begin_date: '', // dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        end_date: '' // dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
      },
      list: [],
      showEmpty: false,
      show: false,
      receiveData: null,
      currentDate: new Date(),
      showPop: false,
      chooseDateType: '',
      checkAll: false,
      chooseAmount: 0,
      chooseReturnAmount: 0
    }
  },
  created() {
    this.initData()
    this.getList()
  },
  methods: {
    initData() {
      this.receiveData = {
        nums: [],
        to_iou: false,
        receive_mode: 1,
        receive_date: ''
      }
    },
    getList() {
      this.beginLoad()
      this.showEmpty = false
      getClientUnpaidDetail(this.listQuery).then(res => {
        this.endLoad()
        this.list = res.data
        this.showEmpty = this.list.length === 0
      })
    },
    reload() {
      this.show = false
      this.getList()
    },
    onSearch() {
      this.reload()
    },
    showDatePicker(chooseDateType) {
      this.showPop = true
      this.chooseDateType = chooseDateType
    },
    handleReceive() {
      this.show = true
      // 计算已选择的签单总额及签单退货总额
      this.chooseReturnAmount = 0
      this.chooseAmount = 0
      this.list.forEach(order => {
        if (this.receiveData.nums.findIndex(num => order.num === num) !== -1) {
          this.chooseReturnAmount = (Number(this.chooseReturnAmount) + Number(order.unpaid_return_amount)).toFixed(2)
          this.chooseAmount = (Number(this.chooseAmount) + Number(order.unpaid_amount)).toFixed(2)
        }
      })
    },
    handleChooseDate(date) {
      date = toDateString(date)
      if (this.chooseDateType === 'receive') {
        this.receiveData.receive_date = date
      } else if (this.chooseDateType === 'begin') {
        this.listQuery.begin_date = date
        this.reload()
      } else {
        this.listQuery.end_date = date
        this.reload()
      }
      this.showPop = false
    },
    receiveOrder() {
      if (!this.receiveData.receive_date) {
        this.fail('请选择收款日期')
        return
      }
      if (this.receiveData.to_iou && !this.receiveData.real_amount) {
        this.fail('请输入实收金额')
        return
      }
      if (this.receiveData.nums.length === 0) {
        this.fail('请选择签单')
        return
      }
      const data = Object.assign({}, this.receiveData)
      data.to_iou = Number(data.to_iou)
      receiveUnpaid(data).then(res => {
        this.success(res.msg)
        this.initData()
        this.reload()
      })
    },
    handleToggle(index) {
      const ref = 'checkbox-' + index
      this.$refs[ref][0].toggle()
    },
    handleCheckboxChange() {
      if (this.receiveData.nums.length !== this.list.length) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    },
    toUnpaidReceiveView() {
      this.$router.push({
        path: '/client-unpaid-receive',
        query: {
          client_id: this.$route.query.client_id
        }
      })
    },
    handleCheckAll() {
      this.checkAll = !this.checkAll
      this.$refs.checkboxGroup.toggleAll(this.checkAll)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/color.scss";
  .list {
    padding: 2px 0;
    margin-top: 180px;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 90px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 90px);
  }
  .filter-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 46px;
    z-index: 9;
  }
  .unpaid-container {
    display: flex;
    background-color: #fff;
    padding: 0 10px;
    margin: 10px;
  }
  .unpaid-check {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .unpaid-info {
    width: 100%;
  }
  .cell-value {
    color: $amountRedColor;
  }
  .cell-title {
    flex: 3;
  }
  .receive-mode-value {
    flex: 2;
  }
  .fixed-bottom-btn {
    height: 90px;
  }
  .receive-radio {
    font-size: 13px;
  }
</style>
