var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "签单明细",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "收款明细"
    },
    on: {
      "click-right": _vm.toUnpaidReceiveView
    }
  }), _c('div', {
    staticClass: "filter-container"
  }, [_c('van-cell', {
    attrs: {
      "title": "开始日期",
      "is-link": "",
      "value": _vm.listQuery.begin_date,
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.showDatePicker('begin');
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "结束日期",
      "is-link": "",
      "value": _vm.listQuery.end_date,
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.showDatePicker('end');
      }
    }
  }), _c('van-search', {
    attrs: {
      "placeholder": "请输入订单编号"
    },
    on: {
      "search": _vm.onSearch
    },
    model: {
      value: _vm.listQuery.num,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "num", $$v);
      },
      expression: "listQuery.num"
    }
  })], 1), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "list"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-checkbox-group', {
    ref: "checkboxGroup",
    on: {
      "change": _vm.handleCheckboxChange
    },
    model: {
      value: _vm.receiveData.nums,
      callback: function callback($$v) {
        _vm.$set(_vm.receiveData, "nums", $$v);
      },
      expression: "receiveData.nums"
    }
  }, _vm._l(_vm.list, function (order, index) {
    return _c('div', {
      key: order.num,
      staticClass: "unpaid-container",
      on: {
        "click": function click($event) {
          return _vm.handleToggle(index);
        }
      }
    }, [_c('van-checkbox', {
      ref: 'checkbox-' + index,
      refInFor: true,
      staticClass: "unpaid-check",
      attrs: {
        "name": order.num
      }
    }), _c('div', {
      staticClass: "unpaid-info"
    }, [_c('van-cell', {
      attrs: {
        "title": "客户名称",
        "value": order.client.name,
        "center": ""
      }
    }), _c('van-cell', {
      attrs: {
        "title": "单据编号",
        "value": order.num,
        "center": ""
      }
    }), order.unpaid_amount > 0 ? _c('van-cell', {
      attrs: {
        "title": "签单金额",
        "value": '￥' + order.unpaid_amount,
        "center": "",
        "value-class": "cell-value"
      }
    }) : _vm._e(), order.unpaid_return_amount < 0 ? _c('van-cell', {
      attrs: {
        "title": "签单退货金额",
        "value": '￥' + order.unpaid_return_amount,
        "center": "",
        "value-class": "cell-value"
      }
    }) : _vm._e(), _c('van-cell', {
      attrs: {
        "title": "下单时间",
        "value": order.created_at,
        "center": ""
      }
    })], 1)], 1);
  }), 0)], 1)]), _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('div', [_c('van-cell', {
    attrs: {
      "title": "全选"
    },
    on: {
      "click": _vm.handleCheckAll
    },
    scopedSlots: _vm._u([{
      key: "right-icon",
      fn: function fn() {
        return [_c('van-checkbox', {
          model: {
            value: _vm.checkAll,
            callback: function callback($$v) {
              _vm.checkAll = $$v;
            },
            expression: "checkAll"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('van-button', {
    attrs: {
      "disabled": _vm.receiveData.nums.length === 0,
      "block": "",
      "square": ""
    },
    on: {
      "click": _vm.handleReceive
    }
  }, [_vm._v("收款")])], 1), _c('my-dialog', {
    attrs: {
      "title": "确认收款",
      "show-cancel-button": ""
    },
    on: {
      "confirm": _vm.receiveOrder
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('div', {
    staticClass: "confirm-data"
  }, [_vm.chooseAmount > 0 ? _c('van-cell', {
    attrs: {
      "value-class": "cell-value",
      "title": "签单总额",
      "value": '￥' + _vm.chooseAmount
    }
  }) : _vm._e(), _vm.chooseReturnAmount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "cell-value",
      "title": "退货总额",
      "value": '￥' + _vm.chooseReturnAmount
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "转成欠条",
      "center": ""
    }
  }, [_c('van-switch', {
    attrs: {
      "size": "20px"
    },
    model: {
      value: _vm.receiveData.to_iou,
      callback: function callback($$v) {
        _vm.$set(_vm.receiveData, "to_iou", $$v);
      },
      expression: "receiveData.to_iou"
    }
  })], 1), _c('van-cell', {
    attrs: {
      "value-class": "receive-mode-value",
      "title": "收款方式",
      "center": ""
    }
  }, [_c('van-radio-group', {
    attrs: {
      "direction": "horizontal"
    },
    model: {
      value: _vm.receiveData.receive_mode,
      callback: function callback($$v) {
        _vm.$set(_vm.receiveData, "receive_mode", $$v);
      },
      expression: "receiveData.receive_mode"
    }
  }, [_c('van-radio', {
    staticClass: "receive-radio",
    attrs: {
      "icon-size": "16px",
      "name": 1
    }
  }, [_vm._v("现金")]), _c('van-radio', {
    staticClass: "receive-radio",
    attrs: {
      "icon-size": "16px",
      "name": 2
    }
  }, [_vm._v("微信")]), _c('van-radio', {
    staticClass: "receive-radio",
    attrs: {
      "icon-size": "16px",
      "name": 3
    }
  }, [_vm._v("转账")])], 1)], 1), _c('van-cell', {
    attrs: {
      "title": "收款日期",
      "value": _vm.receiveData.receive_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.showDatePicker('receive');
      }
    }
  }), _vm.receiveData.to_iou ? _c('van-field', {
    attrs: {
      "label": "实收金额",
      "placeholder": "请输入实收金额",
      "type": "number"
    },
    model: {
      value: _vm.receiveData.real_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.receiveData, "real_amount", $$v);
      },
      expression: "receiveData.real_amount"
    }
  }) : _vm._e()], 1)]), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showPop,
      callback: function callback($$v) {
        _vm.showPop = $$v;
      },
      expression: "showPop"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择日期"
    },
    on: {
      "confirm": _vm.handleChooseDate,
      "cancel": function cancel($event) {
        _vm.showPop = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }